



























































































































































































.plans_subscriptions_search {
  .customer_name {
    &:hover {
      .link_icon {
        transform: scale(1);
      }
    }
    .link_icon {
      transform: scale(0);
    }
  }
}
